@keyframes animatebg {
  0%   { background-color: #FFFAD7; }
  20%  { background-color: #FFEFD6; }
  40%  { background-color: #F2DEBA; }
  60%  { background-color: #F2DEBA; }
  80%  { background-color: #FFEFD6; }
  100% { background-color: #FFFAD7; }
}

@keyframes colorbgmatch {
  0%  { color: #A64B2A; }
  100%  { color: #A64B2A; }
}


body{
  animation: animatebg 30s infinite linear;
  
  background-image: url('./bg.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.backclick {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.breadcrumb {
  position: relative;
  z-index: 1;
}

.dynamicForm {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
  background: rgba(255,255,255,0.5);
}

.containerX {
  margin: auto;
  max-width: 320px;
  width: 100%;
}


main {
  min-height: calc(100vh - 158px);
}

footer {
  padding: 30px;
  color: #4bc5f0;
  background: #395e6c;
}

.App-header{
  padding: 5px 15px;
}
.App-header h1{
  animation: colorbgmatch 30s infinite linear;
  margin: 0;
  font-size: 27px;
  padding: 0 10px;
}

.logo{
  display: flex;
  align-items: center;
}
.wrapper {
  padding: 20px;
}

.sidemenu{
  position: sticky;
  top: 0;
  max-height: 100vh;
}

.file-grid {
  display: flex;
  flex-wrap: wrap;

}

.file-list .col-xl-2.col-md-3.col-sm-4.col-6.mb-2 {
  width: 100% !important;
}

.file-list .card.h-100 {
  flex-direction: row;
}

.file-list .card-body {
  display: none;
}

.fileLink{
  cursor: pointer;
  text-align: center;
}

.fileLink:hover, .fileLink a:hover, .focused, .focused a {
  color: #FCCA00;
}
.fileLink img {
  max-width: 100%;  
transition: 1s;
}

.fileLink:hover img{
  transition: 1s;
  -webkit-box-shadow: inset 4px 6px 21px -4px rgba(0,0,0,0.75);
-moz-box-shadow: inset 4px 6px 21px -4px rgba(0,0,0,0.75);
box-shadow: inset 4px 6px 21px -4px rgba(0,0,0,0.75);
}

.breadcrumb-item.active a{
  color:#333;
  text-decoration: none;
}
.file-grid div.card{
  margin: 5px;
  
}

.file-grid .card-body{
  
  justify-content: center;
  align-items: center;
  display: flex;
}

.file-grid .card-footer {
  overflow: hidden;
  max-height: 40px;
}
.file-grid .card-footer img {
  float: left;
  position: relative;
  top: 0;
  left: -5px;
  padding: 5px 5px 5px 0;
}

#result{
  
  position: fixed;
  right: 5px;
  bottom: 5px;
  max-width: 450px;
  overflow-wrap: break-word;
  z-index: 10;
  max-height: 90vh;
  overflow: auto;
  padding-right: 15px;
}

#result .close{
  
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 5px;
  text-decoration: none;
  color: #363636;
  background: none;
  border: none;
  font-size: 21px;
}

#result .close:hover {
  color: #999;
}


#result .list-group-item{
  padding-right: 36px;
}

.context-menu { 
  position: absolute; 
  z-index: 999;
} 
.context-menu .menu {
  display: flex;
  list-style: none;
  flex-direction: column;
  background-color: #fff;
  -webkit-box-shadow: 0px -1px 15px 11px rgba(87,87,87,1);
-moz-box-shadow: 0px -1px 15px 11px rgba(87,87,87,1);
box-shadow: 0px -1px 15px 11px rgba(87,87,87,1);
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(64 64 64 / 5%);
  padding: 10px 0;
}
.context-menu .menu > li > a {
  font: inherit;
  border: 0;
  padding: 10px 30px 10px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: unset;
  color: #000;
  font-weight: 500;
  transition: 0.5s linear;
  -webkit-transition: 0.5s linear;
  -moz-transition: 0.5s linear;
  -ms-transition: 0.5s linear;
  -o-transition: 0.5s linear;
}
.context-menu .menu > li > a:hover {
  background:#f1f3f7;
  color: #4b00ff;
}
.context-menu .menu > li > a > i {
  padding-right: 10px;
}
.context-menu .menu > li.trash > a:hover {
  color: red;
}

.drivemenu{
  
  box-shadow: 1px -1px 9px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px -1px 9px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 1px -1px 9px 0px rgba(0,0,0,0.75);
}

.file-Uploader{
  
  border-radius: 15px;
  background: rgba(255,255,255,0.9);
  box-shadow: 1px -1px 9px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 1px -1px 9px 0px rgb(0 0 0 / 75%);
  -moz-box-shadow: 1px -1px 9px 0px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.file-Uploader h4{
  margin-top: 15px;
}
.file-Uploader input[type="file"]{
  opacity: 0;
  width: 100%;
  height: 72%;
  position: absolute;
  left: 0;
  z-index: 1;
}

@keyframes animatecircle {
  0%   { box-shadow: 0 0 18px #00aced; }
  50%  { box-shadow: 0 0 9px #00aced; }
  100% { box-shadow: 0 0 18px #00aced; }
}

.file-Uploader .boundry {
  position: relative;
  z-index: 0;
  margin: 60px;
  border-radius: 50%;
  border: 3px solid #00aced;
  animation: animatecircle 1s infinite linear;
  transition: all 0.5s ease;
}

.file-Uploader .boundry:hover{
  -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
     animation-play-state: paused;
}

.file-Uploader .boundry .dragndrop{
  text-align: center;
  font-size: 21px; 
  padding: 27px 54px;
  color: #333 !important;
  align-self: center;
  justify-content: center;
  transition: all 0.5s ease;
}


@media screen and (max-width: 667px) {
  .sidemenu{
    display: none;
  }
}